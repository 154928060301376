#Banner0_0.kiyvlqeglac-editor_css {
  background-image: url('../images/banner1.jpg');
}
#Banner0_0 .kiyy6n1ou5-editor_css {
  color: #fb9224;
}
#Banner0_0 .banner0-text-wrapper > .kiyy432xptm-editor_css {
  font-size: 72px;
  width: 600px;
}
#Banner0_0 .banner0-text-wrapper > .kiyy6kxt5uf-editor_css {
  font-size: 36px;
}
#Content1_0 .ant-row > .ant-col > .kiz3l1ea5vo-editor_css {
  color: #fb9224;
}
#Feature2_0 .ant-row > .ant-col > .kiz3mgzfoh-editor_css {
  color: #fb9224;
}
#Feature1_0 .ant-row > .ant-col > .kiz3myh9q1u-editor_css {
  color: #fb9224;
}
#Feature1_0 .ant-row > .ant-col > .kiz3nuvuzu-editor_css {
  font-size: 18px;
}
#Feature2_0 .ant-row > .ant-col > .kiz3o7kjp9o-editor_css {
  font-size: 18px;
}
#Content1_0 .ant-row > .ant-col > .kiz3oeq99s6-editor_css {
  font-size: 18px;
}
#Banner0_0 .banner0-text-wrapper > .kiyyivcoc2-editor_css {
  font-size: 18px;
  color: #fb9224;
  border-top-color: rgb(251, 146, 36);
  border-right-color: rgb(251, 146, 36);
  border-bottom-color: rgb(251, 146, 36);
  border-left-color: rgb(251, 146, 36);
}
#Content5_0 .home-page > .title-wrapper > .kiz4nlkyti6-editor_css {
  color: #fb9224;
}
